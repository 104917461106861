import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { generateUnsubscriptionLink } from "../apis/middlewareEndpoints";

const UnsubscribeRedirection = () => {
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    const campaignId = params.get("campaign_id");

    if (email && campaignId) {
      generateUnsubscriptionLink({ email, campaignId, locale: "ja" });
    } else {
      console.error("Required query parameters missing");
    }
  }, [history]);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "grid",
        placeItems: "center",
      }}>
      <div
        className="tw-flex tw-flex-col tw-items-center tw-justify-center"
        style={{
          gap: "1rem",
        }}>
        <div
          className="tw-flex tw-flex-col tw-items-center"
          style={{
            gap: "1rem",
          }}>
          <div className="tw-animate-pulse tw-transform">
            <img
              src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AYANA_Rewards_logo_2023_6baf3ef4d3.png"
              className="tw-max-w-[120px]"
              alt="ayana-rewards-logo"
            />
          </div>
        </div>
        <p className="tw-text-[20px] tw-leading-[28px] tw-opacity-50 tw-animate-pulse tw-font-serif">
          Redirecting...
        </p>
      </div>
    </div>
  );
};

export default UnsubscribeRedirection;
